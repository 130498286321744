.breadcrumb {
  padding: 10px 0 !important;
  margin-bottom: 0 !important;
  background-color: #fff !important;
  z-index: 10;
  position: relative;
}

.breadcrumb ul li {
  display: inline-block;
  position: relative;
  padding: 0 15px;
}

.breadcrumb ul li:after {
  content: "\f054";
  position: absolute;
  font-size: 15px;
  font-family: "Font Awesome 6 Free";
  font-weight: 700;
  top: 0px;
  right: -6px;
}

.breadcrumb ul li:last-child:after {
  display: none;
}

.breadcrumb ul li:last-child a {
  font-weight: 300;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
}

.breadcrumb ul li a {
  font-family: "Brinnan_Regular";
  color: #302946;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
}

@media (max-width : 575px) {
  .breadcrumb ul li a {
    font-size: 13px;
    line-height: 20px;
  }

  .breadcrumb ul li {
    padding: 0 9px;
  }

  .breadcrumb ul li:last-child a {
    font-size: 13px;
  }

  .breadcrumb ul li:after {
    font-size: 13px;
    top: 3px;
    right: -5px;
  }
}