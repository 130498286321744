.review_banner {
    background-image: url(./../../assets/images/testimonial-banner-bg.webp);
    padding: 140px 0 120px;
    position: relative;
}

.review_banner h1 {
    font-weight: 500;
    text-transform: capitalize;
    margin: 0 0 20px;
    font-size: 46px;
    line-height: 56px;
}

.review_banner p {
    margin: 0 0 10px 0;
    font-size: 20px;
    line-height: 32px;
}

.review_slider_item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.review_slider_item img {
    height: 150px;
    width: 150px;
    margin: 0 auto;
    opacity: 0.6;
}

.slick-active.slick-cloned .review_slider_item img {
    opacity: 1;
}


.counter_wrap {
    background: #fff;
    width: 100%;
    margin: -120px auto 50px;
    padding: 30px 00px;
    border-radius: 10px;
    box-shadow: 0 5px 20px -3px #b2b2b2;
    position: relative;
}

.counter-content-box {
    text-align: center;
    margin: 0 20px 0 0;
}

.counter-content-box h3 {
    font-size: 50px;
    line-height: 52px;
    margin: 0 0 10px;
    color: #00113c;
    font-weight: 600;
}

.counter-content-box h3 span {
    color: #00113c;
}

.counter-content-box p {
    color: #333;
    font-size: 15px;
    margin: 0 0 10px 0;
    line-height: 22px;
    font-weight: 500;
}





section.tab-agencies-wrap {
    background-color: #f4f4f4;
    padding: 50px 0 50px;
    position: relative;
}

.tab-agencies-wrap .testi-count-stats {
    background: #fff;
    width: 100%;
    margin: -120px auto 50px;
    padding: 30px 30px;
    border-radius: 10px;
    box-shadow: 0 5px 20px -3px #b2b2b2;
}

.testi-count-stats .testi-count-items {
    text-align: center;
}

.testi-count-items h3 {
    font-size: 50px;
    line-height: 52px;
    margin: 0 0 10px;
    color: #00113c;
    font-weight: 600;

}

.testi-count-items h3 span {
    color: #00113c;
}

.testi-count-items p {
    color: #333;
    font-size: 16px;
    margin: 0 0 10px 0;
    line-height: 22px;
    font-weight: 500;
}

.tab-agencies-wrap .tab-agencies-btns {
    border-right: 2px solid #b2b2b2;
    margin: 0 0 20px 0;
}

.tab-agencies-btns .nav-tabs {
    border: none
}

.tab-agencies-btns .nav-pills .nav-link {
    color: #333 !important;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    padding: 5px 10px;
    line-height: 22px;
    border-left: 5px solid #b2b2b2;
    border-radius: 0px;
    margin: 0 0 10px 0;
    transition: all .3s ease-in-out;
    border-bottom: 0px;
    border-top: 0px !important;
}

.tab-agencies-btns .nav-pills .nav-link.active {
    background: none;
    color: #e80000 !important;
    border-left: 5px solid #00113c;
}


.tab-item-content h2 {
    color: #00113c;
    font-weight: 600;
    text-transform: capitalize;
    font-family: Poppins, sans-serif;
    margin: 0px 0 10px 0;
    font-size: 40px;
    line-height: 46px;

}

.tab-item-content .tab-item-box-left {
    margin: 20px 0 10px 0;
    background-image: url(./../../assets/images/testimonials-bg.webp);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 20px 20px;
    border-radius: 10px;
    box-shadow: 0 5px 20px -3px #b2b2b2;
    text-align: center;
    position: relative;
    z-index: 1;
    min-height: 500px;
    max-height: 500px;
}

.tab-item-content .tab-item-box-left::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #00113c;
    opacity: 0.8;
    z-index: -1;
    border-radius: 10px;
}

.tab-item-box-left img {
    margin: 0 auto 20px;
    height: 200px;
    width: 200px;
    border-radius: 100%;
    object-fit: cover;
}

.tab-item-box-left .item-box-left-inner {
    padding: 0;
}

.item-box-left-inner p {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding: 0 10px 0 0;
    line-height: 24px;
    margin: 0 0 10px 0;
    min-height: 150px;
    max-height: 150px;
    overflow-y: auto;
}

.item-box-left-inner p::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.item-box-left-inner p::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

.item-box-left-inner p::-webkit-scrollbar-thumb {
    background-color: #e80000;
}

.item-box-left-inner h4 {
    color: #e80000;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin: 0 0 20px 0;
    letter-spacing: 0;
}

.tab-item-content .tab-item-box-right {
    margin: 20px 0 10px 0;
    background-image: url(./../../assets/images/testimonials-bg.webp);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    align-items: center;
    padding: 20px 20px;
    border-radius: 10px;
    box-shadow: 0 5px 20px -3px #b2b2b2;
    position: relative;
    z-index: 1;
}

.tab-item-content .tab-item-box-right::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #00113c;
    opacity: 0.8;
    z-index: -1;
    border-radius: 10px;
}

.tab-item-box-right img {
    border-radius: 100%;
    margin: 0;
    height: 200px;
    width: 200px;
    border-radius: 100%;
}

.tab-item-box-right .item-box-right-inner {
    padding: 0;
    margin: 0 10px 0 0;
    width: 65%;
}

.item-box-right-inner p {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding: 0 10px 0 0;
    line-height: 24px;
    margin: 0 0 10px 0;
    min-height: 100px;
    max-height: 100px;
    overflow-y: auto;
}

.item-box-right-inner p::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.item-box-right-inner p::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

.item-box-right-inner p::-webkit-scrollbar-thumb {
    background-color: #e80000;
}

.item-box-right-inner h4 {
    color: #e80000;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin: 0 0 20px 0;
    letter-spacing: 0;
}

.tab-item-content .tab-item-box {
    margin: 20px 0 20px 0;
    background-image: url(./../../assets/images/testimonials-bg.webp);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 20px 20px;
    border-radius: 10px;
    box-shadow: 0 5px 20px -3px #b2b2b2;
    text-align: center;
    position: relative;
    z-index: 1;
}

.tab-item-content .tab-item-box::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #00113c;
    opacity: 0.8;
    z-index: -1;
    border-radius: 10px;
}

.tab-item-box img {
    border-radius: 100%;
    margin: 0 auto 20px;
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.tab-item-box .item-box-inner {
    padding: 0;
}

.item-box-inner p {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding: 0 10px 0 0;
    line-height: 24px;
    margin: 0 0 10px 0;
    min-height: 100px;
    max-height: 100px;
    overflow-y: auto;
}

.item-box-inner p::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.item-box-inner p::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

.item-box-inner p::-webkit-scrollbar-thumb {
    background-color: #e80000;
}

.item-box-inner h4 {
    color: #e80000;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin: 0 0 20px 0;
    letter-spacing: 0;
}




section.testi-sec-wrap {
    padding: 50px 0;
    background: #fff;
}

.testi-sec-wrap .testi-sec-head {
    text-align: center;
    margin: 0 0 40px 0;
    position: relative;
    z-index: 1;
}

.testi-sec-wrap .testi-sec-head h2 {
    text-align: center;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 30px;
    line-height: 45px;
    color: #00113c;
    width: 70%;
    margin: 0px auto 10px;
}

.testi-sec-wrap .testi-sec-head p {
    color: #333;
    font-size: 20px;
    line-height: 28px;
    margin: 0 auto 20px;
    width: 75%;
}

.testi-sec-wrap .testi-sec-head p a {
    color: #e80000;
    font-weight: 500;
}


.testi-sec-inner .testi-sec-slider {
    padding: 20px 0 0 0;
    position: relative;
    z-index: 1;
}

.testi-sec-slider .slick-slide {
    padding: 0 20px 0;
}

.testi-sec-slider .slick-arrow {
    display: none;
}

.testi-sec-slider .test-sec-box {
    background: #fff;
    padding: 10px 30px 30px;
    box-shadow: 0 2px 20px -3px #b2b2b2;
    text-align: center;
    margin: 50px 10px 25px;
    border-radius: 20px;
    opacity: 1;
    transition: all .3s ease-in-out;
    position: relative;
    z-index: 1;
}

.test-sec-box .testi-box-image {
    margin: -50px 0 30px 0
}

.test-sec-box .testi-box-image img {
    width: 85px;
    height: 85px;
    border-radius: 100%;
    border: 1px solid #e80000;
    margin: 0 auto;
}

.test-sec-box h3 {
    color: #00113c;
    font-size: 22px;
    line-height: 28px;
    font-weight: 500;
    margin: 0;
    letter-spacing: 0.5px;
}

.test-sec-box p {
    color: #333;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    padding: 0 20px 0 0;
    margin: 0 0 20px 0;
}

.test-sec-box .testi-box-social {
    margin: 10px 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.test-sec-box .testi-box-social i.fa-facebook {
    font-size: 30px;
    color: #3b5998;
    margin: 0 5px 0;
}

.test-sec-box .testi-box-social i.fa-twitter {
    font-size: 30px;
    color: #00acee;
    margin: 0 5px 0;
}

@media (max-width : 1440px) {
    .counter-content-box p {
        font-size: 13px;
    }

    .testi-sec-wrap .testi-sec-head h2 {
        font-size: 28px;
        line-height: 40px;
        width: 91%;
    }
}

@media (max-width : 1199px) {
    .review_banner h1 {
        font-size: 38px;
        line-height: 48px;
    }

    .review_banner p {
        font-size: 17px;
        line-height: 28px;
    }

    .counter-content-box p {
        font-size: 11px;
    }

    .tab-agencies-btns .nav-pills .nav-link {
        font-size: 16px;
    }
}

@media (max-width: 991px) {
    .review_banner h1 {
        font-size: 28px;
        line-height: 37px;
    }

    .review_banner p {
        font-size: 14px;
        line-height: 24px;
    }

    .review_slider_item img {
        height: 100px;
        width: 98px;
        margin: 0 auto;
        opacity: 0.6;
    }

    .testi-sec-wrap .testi-sec-head h2 {
        font-size: 18px;
        line-height: 25px;
        width: 80%;
    }

    .testi-sec-wrap .testi-sec-head p {
        font-size: 15px;
        width: 85%;
        line-height: 25px;
    }
}

@media (max-width : 767px) {
    .testi-sec-wrap .testi-sec-head h2 br {
        display: none;
    }
}

@media (max-width : 575px) {
    .testi-sec-wrap .testi-sec-head h2 {
        width: 100%;
        font-size: 22px;
    }

    .testi-sec-wrap .testi-sec-head p {
        width: 100%;
        font-size: 13px;
    }
    .tab-item-box-right img{
        height: auto;
        max-width: 100%;
    }
}