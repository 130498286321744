.thankyou_sec {
    padding: 100px 0;
    height: 85vh;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}

.main-thankyou {
    text-align: center;
}

.site-header__title {
    font-size: 6.5rem;
    font-weight: 700;
    line-height: 1.1;
}


.main-content__checkmark {
    font-size: 9.75rem;
    line-height: 1;
    color: #e80000;
}

.main-content__body {
    margin: 20px 0 0;
    font-size: 1.25rem;
    line-height: 1.4;
}

@media (max-width : 575px) {
    .main-content__checkmark {
        font-size: 50px !important;
        line-height: 30px !important;
    }

    .site-header__title {
        font-size: 40px;
        line-height: 50px;
    }

    .main-content__body {
        margin: 10px 0 10px;
        font-size: 13px;
    }

    .thankyou_sec {
        height: auto;
    }
}