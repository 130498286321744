.inner_banner {
  background-color: #e80000;
  padding: 200px 0 100px;
  text-align: center;
}

.inner_banner h1 {
  font-size: 50px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 60px;
  color: #ffffff;
}


@media (max-width : 575px) {
  .inner_banner h1 {
    font-size: 31px;
    line-height: 40px;
  }

  .inner_banner {
    padding: 120px 0 40px;
  }
}

/*Inner Banner End*/