.main_banner {
  padding: 160px 0 80px;
  background-size: cover;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-image: url(./../../assets/images/home-banner.webp);
  position: relative;
  z-index: 10;
}

.main_banner.ghostwriting-services,
.main_banner.horror-writing,
.main_banner.autobiography-service,
.main_banner.biography-writing,
.main_banner.rhyme-writing,
.main_banner.science-fiction-writing,
.main_banner.author-website-design,
.main_banner.book-video-trailer {
  background-image: url(./../../assets/images/ghostwriting-bg.webp);
}

.main_banner.non-fiction-writing,
.main_banner.manuscript-writing-service,
.main_banner.military-fiction-writing,
.main_banner.screenplay-writing,
.main_banner.childrens-book-writing,
.main_banner.memoir-writing-services,
.main_banner.comic-book-writing-services,
.main_banner.fantasy-writing,
.main_banner.story-writing,
.main_banner.book-illustration-design,
.main_banner.book-marketing-services,
.main_banner.book-printing-services {
  background-image: url(./../../assets/images/marketing-bg.webp);
  background-position: 100% 10%;
}

.main_banner.action-adventure-writing,
.main_banner.book-editing-services {
  background-image: url(../../assets/images/book-editing-bg.webp);
}

.main_banner.book-proofreading-services {
  background-image: url(../../assets/images/proofreading-bg.webp);
  background-position: 100% 80%;
}

.main_banner.book-cover-design-services {
  background-image: url(../../assets/images/coverdesign-bg.webp);
  background-position: 100% 80%;
}

.main_banner.book-publishing {
  background-image: url(../../assets/images/publishing-bg.webp);
  background-position: 100% 80%;
}

.main_banner::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  z-index: -1;
  opacity: 0.9;
  width: 100%;
}

.banner_content h1 {
  font-size: 50px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 25px;
}

.banner_content p {
  font-size: 20px;
  line-height: 28px;
  color: #fff;
  margin-bottom: 0px;
}

.platforms_logo {
  display: flex;
  margin-top: 20px;
}

.platforms_logo img {
  margin-right: 20px;
  width: 110px;
  object-fit: contain;
}

@media (max-width: 1199px) {
  .banner_content h1 {
    font-size: 40px;
  }

  .banner_content p {
    font-size: 16px;
    line-height: 26px;
  }
}

@media (max-width: 991px) {
  .main_banner {
    padding: 120px 0 80px;
  }
}

@media (max-width: 767px) {
  .banner_content h1 {
    font-size: 34px;
  }
}

@media (max-width: 575px) {
  .banner_content h1 {
    font-size: 27px;
  }

  .banner_content p {
    font-size: 14px;
    line-height: 24px;
  }

  .platforms_logo img {
    margin-right: 0px;
    width: 100px;
  }

  .platforms_logo {
    flex-wrap: wrap;
  }
}