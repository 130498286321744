.dropdown-tabs {
  text-align: center;
}

.dropdown-tabs select {
  background: #ffffff;
  padding: 5px 35px 5px 10px;
  box-shadow: 0 0 3px 1px #b2b2b2;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 20px;
  color: #00113c;
  margin: 0px 0 0px 0;
  line-height: 40px;
  border-color: transparent;
  height: 55px;
  width: 590px;
  margin-bottom: 40px;
}

.CatPortfolioSlider .item {
  padding: 0px 15px;
}

.CatPortfolioSlider img {
  width: 100%;
}

.CatPortfolioSlider {
  margin-bottom: 20px;
}

.CatPortfolioSlider .slick-dots {
  bottom: -45px;
  text-align: center !important;
}

.CatPortfolioSlider .slick-prev:before,
.CatPortfolioSlider .slick-next:before {
  color: #9c9c9c;
}

.CatPortfolioSlider .slick-prev {
  left: -35px;
}

.CatPortfolioSlider .slick-next {
  right: -35px;
}

.dropdown-tabs select option {
  background: #00113c;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

@media (max-width :767px) {
  .dropdown-tabs select {
    width: 100%
  }
}

@media (max-width : 575px) {
  .CatPortfolioSlider .slick-next {
    right: 0px;
  }

  .CatPortfolioSlider .slick-prev {
    left: 0px;
  }
}