.partners_logo {
  padding: 30px 0;
  background-color: #e80000;
  position: relative;
  z-index: 10;
}

.partners_logo img {
  filter: brightness(0) invert(1);
  margin: auto;
  max-width: 150px;
}

@media (max-width : 575px) {
  .partners_logo {
    padding: 20px 0;
  }
}