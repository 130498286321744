footer.footer-main-sec {
  background-color: #00113c;
  position: relative;
  z-index: 10;
}

.footer-main-sec .footer-top {
  padding: 50px 0 20px 0;
}

.footer-top .footer-links-area {
  margin: 0 0 20px;
  border-right: 1px solid #3D3D3D;
  height: 100%;
}

.footer-top .footer-links-area.footer-links-area-last {
  border-right: none;
}

.footer-links-area h4 {
  font-size: 20px;
  line-height: 26px;
  color: #fff;
  margin: 0 0 20px 0;
}

.footer-links-area .footer-services-nav-links {
  display: flex;
  justify-content: space-between;
}

.footer-services-nav-links ul.footer-nav-links {
  margin: 0 20px 0 0;
}

.footer-links-area ul.footer-nav-links li {
  margin: 0 0 10px 0;
}

.footer-links-area ul.footer-nav-links a {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #dededf;
  margin: 0;
  transition: all .3s ease-in-out;
  display: block;
}

.footer-links-area ul.footer-nav-links a:hover {
  color: #e80000;
}

.footer-top .footer-brands-area {
  margin: 20px 0;
}

.footer-brands-area .footer-brands-logos {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
}

.footer-brands-area .footer-brands-logos img {
  width: 125px;
  height: 75px;
  object-fit: contain;
  margin: 0 10px 0;
}

.footer-brands-area .footer-dmc-logos {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
}

.footer-brands-area .footer-dmc-logos img {
  margin: 0 10px 0;
}


.footer-top .footer-disclaimer-area p {
  color: #fff;
  font-size: 13px;
  line-height: 20px;
  margin: 0 0 10px;
}

.footer-top .footer-disclaimer-area p b {
  color: #e80000;
}

.footer-main-sec .footer-middle {
  background: #e80000;
  padding: 20px 0;
}

.footer-middle .footer-logo-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-logo-area .footer-logo {
  width: 150px;
}

/* .footer-logo-area .footer-info {} */

.footer-logo-area .footer-info ul {
  text-align: center;
}

.footer-logo-area .footer-info ul li {
  margin: 0 0 5px;
}

.footer-logo-area .footer-info ul li a,
.footer-logo-area .footer-info ul li p {
  color: #fff;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin: 0;
  display: block;
  transition: all .3s ease-in-out;
}

.footer-logo-area .footer-info ul li:last-child {
  margin: 0;
}

.footer-logo-area .footer-info ul li a:hover {
  color: #00113c;
}

.footer-logo-area ul.footer-social-links {
  display: flex;
  align-items: center;
}

ul.footer-social-links li {
  margin: 0 7px 0;
}

ul.footer-social-links li a {
  color: #fff;
  font-size: 22px;
  display: block;
  transition: all .3s ease-in-out;
}

ul.footer-social-links li a:hover {
  color: #00113c;
}

.footer-main-sec .footer-bottom {
  padding: 10px 0;
  background: transparent;
}

.footer-bottom .footer-copyright {
  text-align: center;
  display: flex;
  align-items: center;
  gap: 11rem;
}

.footer-copyright p {
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}

.footer-copyright ul.footer-nav-links a {
  font-size: 14px;
  color: #fff;
}

.footer-copyright ul.footer-nav-links {
  display: flex;
}

.footer-copyright ul.footer-nav-links li {
  margin: 0 10px;
}


@media (max-width : 767px) {
  .footer-top .footer-links-area {
    border-right: 0;
  }

  .footer-brands-area .footer-brands-logos img {
    width: 105px;
    height: 54px;
    margin: 0 4px 0;
  }

  .footer-brands-area .footer-brands-logos {
    margin: 0 0 10px;
  }

  .footer-middle .footer-logo-area {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer-logo-area .footer-logo {
    width: 150px;
    margin-right: 30px;
  }

  .footer-logo-area ul.footer-social-links {
    margin-top: 20px;
  }

  .footer-bottom .footer-copyright {
    gap: 9px;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (max-width : 575px) {
  .footer-logo-area .footer-info {
    margin: 15px 0;
  }

  .footer-logo-area ul.footer-social-links {
    margin-top: 0;
  }

  .footer-brands-area .footer-brands-logos img {
    width: 93px;
  }

  .footer-logo-area .footer-logo {
    margin-right: 0;
  }
}