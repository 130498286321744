.modal {
  top: 10%;
  z-index: 99999;
}

.modal-content {
  background-color: #e80000 !important;
  width: 990px;
}

#penguinBackdrop .modal.show .modal-dialog {
  transform: none;
  max-width: fit-content;
}

.popup-text-wrap {
  padding-left: 50px;
}

.popup-text-wrap h6 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 25px;
}

.popup-text-wrap h3 {
  font-size: 54px;
  font-weight: bold;
  color: #fff;
  line-height: normal;
  padding-bottom: 20px;
  margin-right: -60px;
}

.popup-text-wrap h3 span {
  color: #fff;
  display: inline;
}

.popup-field {
  display: flex;
  align-items: center;
  padding: 0 25px;
  height: 50px;
  border-radius: 5px;
  margin-bottom: 10px;
  gap: 10px;
  background-color: #fff;
  max-width: 440px;
}

.popup-field span {
  font-size: 18px;
  color: #e80000;
}

.popup-field input {
  width: 100%;
  height: 100%;
  color: #000;
  font-size: 16px;
  background: transparent;
  border: none;
}

.popup-field input:focus-visible {
  outline: none;
  border: none;
}

.popup-form-wrap button {
  border: 2px solid #fff;
  height: 70px;
  text-align: center;
  line-height: 70px;
  background-color: #fff;
  width: 100%;
  max-width: 440px;
  border-radius: 5px;
  font-size: 30px;
  font-weight: bold;
  color: #e80000;
  cursor: pointer;
  box-shadow: 0px 26px 51px 0px rgb(0 0 0 / 35%);
  margin-top: 10px;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 2;
}

.popup-form-wrap button:hover {
  background-color: transparent;
  color: #fff;
}

.pop-img {
  margin-top: 0px;
  position: relative;
  right: -40px;
}

a.cross {
  position: absolute;
  border: 2px solid #fff;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  right: 0;
  z-index: 99;
}

div#penguinBackdrop {
  min-height: auto;
}

div#penguinBackdrop .modal-body {
  padding-bottom: 0px;
}

@media (max-width: 1600px) {
  div#penguinBackdrop {
    max-width: 860px;
  }

  .popup-text-wrap h3 {
    font-size: 38px;
    margin-right: 0px;
  }

  .popup-text-wrap {
    padding-left: 38px;
  }
}

@media (max-width : 1199px) {
  .popup-text-wrap h3 {
    font-size: 38px;
    line-height: 44px;
    padding-bottom: 10px;
  }

  .popup-text-wrap h6 {
    font-size: 15px;
    padding-bottom: 6px;
  }

  .popup-field input {
    font-size: 14px;
  }

  .popup-form-wrap button {
    line-height: 60px;
    font-size: 24px;
    max-width: 100%;
  }

  .popup-text-wrap {
    padding-left: 0px;
  }
}

@media (max-width : 991px) {
  div#penguinBackdrop .modal-body {
    padding: 20px;
  }

  .popup-text-wrap h3 {
    font-size: 25px;
    line-height: 31px;
    margin: 0;
  }

  .popup-text-wrap h6 {
    font-size: 13px;
    padding-bottom: 6px;
  }
}

@media (max-width : 575px) {
  .popup-field {
    padding: 0 12px;
    height: 40px;
    gap: 5px;
  }

  .popup-field input {
    font-size: 13px;
  }

  .popup-form-wrap button {
    line-height: 36px;
    font-size: 18px;
    max-width: 100%;
    height: 46px;
  }
}