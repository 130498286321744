.banner-form {
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 20px;
  width: 75%;
  min-height: 600px;
  max-height: 600px;
  margin: 0 auto 0px;
  text-align: center;
}

.banner-form h2 {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 36px;
  color: #e80000;
  margin: 0px 0 20px 0;
  line-height: 40px;
  border-bottom: 1px solid #00113c;
  padding: 0 0 20px 0;
}

.banner-form p {
  color: #00113c;
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 20px 0;
  text-align: left;
  font-weight: 600;
}


.services_fields {
  display: flex;
  flex-direction: column;
}

.services_fields label {
  border: 2px solid #e80000;
  margin: 0 0 10px 0;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
}

.services_fields label input {
  appearance: none;
  /* Remove default styling */
  -webkit-appearance: none;
  width: 25px;
  height: 25px;
  cursor: pointer;
  border: 2px solid #e80000;
  border-radius: 50%;
  position: relative;
}

.services_fields label input:checked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 14px;
  background-color: #e80000;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.banner-form .step-2 input,
.banner-form .step-2 textarea {
  display: block;
  width: 100%;
  border: none;
  height: 40px;
  padding: 0 20px;
  box-shadow: 0 0 3px 1px #b2b2b2;
  font-size: 14px;
  margin-bottom: 25px;
}

.banner-form .step-2 textarea {
  height: 100px;
  padding-top: 10px;
  resize: none;
}

.services_fields .default_btn {
  margin: 0 auto;
}

.banner-form .step-2 .form-check-privacy input {
  height: auto;
  box-shadow: none;
  width: auto;
  margin-right: 10px;
  margin-top: 5px;
}

.form-check-privacy {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.form-check-privacy p {
  font-size: 14px;
  line-height: 23px;
  margin-bottom: 34px;
}

.form-check-privacy p a {
  color: #e80000;
}


@media (max-width: 1199px) {

  .banner-form {
    min-height: 510px;
    max-height: 510px;
  }

  .banner-form h2 {
    font-size: 31px;
    padding: 0px 0 15px 0;
    margin: 0px 0 15px 0;
  }

  .banner-form p {
    font-size: 15px;
    line-height: 24px;
    margin: 0 0 15px 0;
  }

  .services_fields label {
    margin: 0 0 10px 0;
    padding: 8px 20px;
    font-size: 14px;
  }
}

@media (max-width : 575px) {
  .banner-form h2 {
    font-size: 24px;
    padding: 0px 0 10px 0;
    margin: 0px 0 10px 0;
    line-height: 26px;
  }

  .banner-form p {
    font-size: 13px;
    line-height: 24px;
    margin: 0 0 15px 0;
  }

  .services_fields label {
    margin: 0 0 10px 0;
    padding: 5px 15px;
    font-size: 13px;
  }

  .banner-form {
    min-height: auto;
    max-height: inherit;
    margin-top: 15px;
    padding: 15px 18px;
    width: 100%;
  }
}