/* side-btn-contact start */
.side-contact a {
  position: fixed;
  width: 300px;
  height: 40px;
  right: -260px;
  top: 200px;
  transition: all .3s ease-in-out;
  background: #fff;
  color: #00113c;
  font-size: 16px;
  font-weight: 500;
  z-index: 111;
}

.side-contact a i {
  width: 40px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  color: #fff;
  text-align: center;
  background: #e80000;
  margin-right: 10px;
}

.side-contact a span {
  color: #00113c;
}

.side-contact a:nth-child(2) {
  top: 250px;
}

.side-contact a:hover {
  right: 0;
}

/* side-btn-contact start */

/* side-form css start */
.side-form-wrapper {
  position: fixed;
  right: -401px;
  top: 200px;
  z-index: 111;
  transition: 0.5s;
}

.side-form {
  background: #fff;
  padding: 30px 15px;
  box-shadow: 0 0 3px 1px #b2b2b2;
  width: 400px;
}

.side-form .m-2 {
  margin: 0.5rem 0 !important;
}

.side-form-wrapper .side-form-btn {
  background: #e80000;
  font-size: 14px;
  padding: 10px 20px;
  color: #fff;
  font-weight: 500;
  border: 1px solid #e80000;
  position: absolute;
  transform: rotate(-90deg);
  left: -157px;
  top: 215px;
  border-bottom: none;
  outline: 0 !important;
}

.side-form-wrapper.open-side-form {
  right: 0;
}

.open-side-form .custom-ovarlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000000cc;
  top: 0;
  left: 0;
  z-index: 9999;
}


.side-form-wrapper h3 {
  max-width: 370px;
  color: #333;
  margin-bottom: 15px;
  font-weight: 500;
}

.side-form-wrapper textarea.form-control {
  min-height: 100px;
}

@media (max-width : 575px) {
  .side-form {
    width: 280px;
    padding: 15px;
  }

  .side-form-wrapper h3 {
    margin-bottom: 5px;
    font-size: 18px;
  }

  .side-form .form-control {
    font-size: 13px;
    padding: 2px 10px;
  }

  .side-form-wrapper .side-form-btn {
    font-size: 13px;
    padding: 4px 15px;
    top: 135px;
    left: -139px;
  }

  .side-contact a {
    width: 250px;
    height: 28px;
    right: -222px;
    top: 150px;
    font-size: 13px;
  }

  .side-contact a i {
    width: 28px;
    height: 28px;
    line-height: 30px;
    font-size: 14px;
  }

  .side-contact a:nth-child(2) {
    top: 185px;
  }

  .side-form-wrapper {
    right: -282px;
    top: 194px;
  }

  .side-form-wrapper textarea.form-control {
    min-height: 70px;
  }
}

/* side-form css end */