.countdown-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.time-unit {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Arial', sans-serif;
}

.circle {
    position: relative;
    width: 70px;
    height: 70px;
}

.circle svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
}

.circle svg circle {
    fill: none;
    stroke-width: 10;
    stroke: #60686f;
}

.circle svg .hours-circle {
    stroke: #99ccff;
    stroke-dasharray: 189;
    stroke-dashoffset: 189;
    transition: stroke-dashoffset 1s linear;
}

.circle svg .minutes-circle {
    stroke: #bbffbb;
    stroke-dasharray: 189;
    stroke-dashoffset: 189;
    transition: stroke-dashoffset 1s linear;
}

.circle svg .seconds-circle {
    stroke: #ff9999;
    stroke-dasharray: 189;
    stroke-dashoffset: 189;
    transition: stroke-dashoffset 1s linear;
}

.time-label {
    position: absolute;
    top: 41%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 9px;
    text-transform: uppercase;
    color: #fff;
}
.time-value {
    position: absolute;
    top: 65%; /* Position the time value in the center */
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}

@media (max-width : 470px){
    .countdown-container{
        margin-top: 35px;
    }
}