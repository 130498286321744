section.sec_cta {
    background: url(./../../assets/images/thoughts-bg.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 10;
}

section.sec_cta .cta_inner .cta_left {
    text-align: right;
}

section.sec_cta .cta_inner .cta_right {
    margin: 0 0 0 20px;
}

section.sec_cta .cta_inner .cta_right h3 {
    font-size: 36px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
}

section.sec_cta .cta_inner .cta_right p {
    margin: 0 0 30px 0;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
}

section.sec_cta .cta_inner .cta_right .number a {
    font-size: 20px;
    color: #fff;
    margin: 50px 0 0 0;
    padding-right: 25px;
    font-weight: 600;
}

.cta_right .number {
    margin-top: 12px;
}

@media (max-width: 1366px) {
    section.sec_cta {
        background-position: 27% 100%;
    }
}

@media (max-width : 767px) {
    section.sec_cta .cta_inner .cta_left {
        text-align: center;
        margin-bottom: 20px;
    }
}

@media (max-width :575px) {
    section.sec_cta .cta_inner .cta_right h3 {
        font-size: 24px;
    }

    section.sec_cta .cta_inner .cta_right {
        margin: 0;
    }

    section.sec_cta .cta_inner .cta_right p {
        margin: 0 0 10px 0;
        font-size: 14px;
        line-height: 24px;
    }

    section.sec_cta .cta_inner .cta_right .number a {
        font-size: 16px;
    }
}