.contact_sec {
  position: relative;
  z-index: 10;
  background-color: #fff;
}

.contact_wrapper {
  margin-top: 40px;
}

.footer_form_area {
  background-color: #e80000;
  border-radius: 15px;
  padding: 40px 100px;
  text-align: center;
}

.footer_form_area h3 {
  color: #fff;
  font-weight: 700;
  font-size: 27px;
}

.footer_form_area form input[type="text"],
.footer_form_area form input[type="email"],
.footer_form_area form input[type="number"],
.footer_form_area form textarea {
  width: 100%;
  display: block;
  margin: 70px 0;
  border: none;
  border-bottom: 1px solid white;
  background: transparent;
  color: #fff;
  font-size: 16px;
  outline: none;
}

.footer_form_area form input::placeholder,
.footer_form_area form textarea::placeholder {
  color: #fff;
}

.footer_form_area form label {
  color: #fff
}

.footer_form_area .default_btn {
  border-radius: 100px;
  text-align: center;
  margin-top: 20px;
}

.footer_form_area .form-check {
  text-align: left;
}

.contact-flow {
  min-height: 758px;
  max-height: 758px;
  padding: 2rem 1rem;
  background-color: #f8f8f9;
  border-radius: 9px;
}

.contact-flow__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 40px;
  text-align: center;
}

.ordered-list {
  margin-bottom: 0;
  padding-top: 10px;
  padding-left: 0;
  list-style: none;
  counter-reset: item;
}

.contact-flow__list-item {
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  position: relative;
  margin-bottom: 37px;
  padding-left: 47px;
}

.contact-flow__list-item:before {
  content: counter(item) "";
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #00113c;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  counter-increment: item;
}

.contact_info_box {
  background: #f4f4f4;
  padding: 40px 20px;
  text-align: center;
  width: 75%;
  margin: 0 auto 20px;
  border-radius: 10px;
}

.contact_info_box i {
  color: #e80000;
  font-size: 40px;
  font-weight: 900;
  margin: 0 0px 20px 0;
}

.contact_info_box h3 {
  color: #333;
  font-size: 28px;
  margin: 0 0 10px 0;
}

.contact_info_box a {
  color: #e80000;
  font-weight: 500;
  transition: all .3s ease-in-out;
  font-size: 16px;
  margin: 0;
}

.contact_info_box a:hover {
  color: #333;
}

.contact_info_box p {
  color: #333;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
}

.contact_info_box:first-child {
  margin-top: 30px;
}

@media (max-width : 1199px) {
  .footer_form_area {
    padding: 25px 50px;
  }

  .footer_form_area form input[type="text"],
  .footer_form_area form input[type="email"],
  .footer_form_area form input[type="number"],
  .footer_form_area form textarea {
    margin: 30px 0;
    font-size: 14px;
  }

  .footer_form_area form label {
    font-size: 14px;
  }

  .contact-flow {
    min-height: 497px;
    max-height: 497px;
  }

  .contact-flow__list-item {
    margin-bottom: 23px;
    padding-left: 45px;
  }

  .contact-flow__title {
    font-size: 18px;
    margin-bottom: 20px;
  }
}

@media (max-width : 991px) {
  .contact-flow__title {
    font-size: 17px;
    margin-bottom: 10px;
  }

  .footer_form_area form input[type="text"],
  .footer_form_area form input[type="email"],
  .footer_form_area form input[type="number"],
  .footer_form_area form textarea {
    font-size: 12px;
  }

  .contact-flow__list-item {
    margin-bottom: 23px;
    padding-left: 37px;
    font-size: 12px;
  }

  .footer_form_area {
    padding: 25px 30px;
  }

  .footer_form_area h3 {
    font-size: 23px;
  }

  .footer_form_area form label {
    font-size: 12px;
  }

  .contact-flow__list-item:before {
    width: 22px;
    height: 22px;
    font-size: 12px;
    line-height: 23px;
  }

  .contact-flow {
    min-height: 468px;
    max-height: 468px;
    padding: 15px;
  }
}

@media (max-width : 767px) {
  .contact-flow {
    min-height: auto;
    max-height: initial;
    padding: 23px 30px;
    margin-top: 20px;
  }
}

@media (max-width : 575px) {
  .contact_sec h2 br {
    display: none;
  }
}