.header {
    width: 100%;
    display: block;
    padding-top: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    transition: 0.5s;
    background-color: #00000099 !important;
}

header.scrolled {
    background-color: #000000 !important;
}

a.navbar-brand.header-logo {
    width: 140px;
}

.menu_area {
    justify-content: space-between;
}

.menu_area .navbar-nav {
    padding-left: 140px;
}

.navbar-nav .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    z-index: 1000;
    padding: 0;
    margin: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    width: 220px;
}

.menu_area .dropdown-menu .nav-item a {
    color: #00113c;
    font-size: 15px;
    font-weight: 500;
    transition: all .3s ease-in-out;
    border-top: 1px solid #e80000;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu_area .dropdown-menu .nav-item a:hover {
    color: #e80000;
}

.menu_area .dropdown-menu li.nav-item:first-child>a {
    border: none;
}

.navbar-nav .dropdown-menu.show {
    display: block;
}

.dropdown-two,
.dropdown-single {
    position: absolute;
    top: 0;
    left: 100%;
    margin-left: 0px;
    display: none;
    z-index: 2000;
    border-top-right-radius: 6px;
    border-top-right-radius: 6px;
    box-shadow: -2px 0px 10px 3px #09235c96;
}

.dropdown-two.show,
.dropdown-single.show {
    display: block;
}

.nav-item {
    position: relative;
}

.menu_area .sub_dropdown {
    background-color: #fff;

}

.menu_area .dropdown-two {
    width: 500px;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #e80000;
    border-left: 1px solid #e80000;
}

.menu_area .dropdown-two li {
    width: 50%;
    flex: 0 0 50%;
}

.menu_area .dropdown-two li a {
    border-right: 1px solid #e80000;
    border-bottom: 1px solid #e80000;
    border-top: 0 !important;
}

.dropdown-single li a {
    border-right: 1px solid #e80000;
    border-left: 1px solid #e80000;
    width: 220px;
}

.dropdown-single li:last-child a {
    border-bottom: 1px solid #e80000;
}

.main_nav {
    color: #fff !important;
    font-size: 17px;
    font-weight: 500;
    margin: 0 4px;
}

.b-none {
    border: none !important
}

@media (max-width: 1600px) {
    .menu_area .navbar-nav {
        padding-left: 80px;
    }
}

@media (max-width: 1199px) {
    .menu_area .navbar-nav {
        padding-left: 34px;
    }

    .main_nav {
        font-size: 14px;
    }
}

@media (max-width: 991px) {
    .menu_area .dropdown-menu .nav-item a i {
        font-size: 17px;
        color: #000;
        transform: rotate(90deg);
    }

    .menu_area .navbar-nav {
        padding-left: 0;
        margin: 12px 0;
    }

    .main_nav {
        font-size: 16px;
        border-bottom: 1px solid #fff;
    }

    .menu_area {
        background-color: #00113c;
        padding: 0 30px;
    }

    .navbar-nav .dropdown-menu {
        position: static;
        width: 100%;
    }

    .dropdown-two,
    .dropdown-single {
        position: static;
    }

    .menu_area .dropdown-menu .nav-item a {
        font-size: 14px;
        width: 100%;
        padding: 6px 10px;
    }

    .menu_area .dropdown-two {
        width: 100%;
    }

    .nav-item {
        padding: 0 7px;
    }

    button.navbar-toggler {
        border: 0px;
    }

    .navbar-toggler i {
        color: #fff;
    }
}

@media (max-width : 575px) {
    .menu_area .dropdown-menu .nav-item a {
        font-size: 12px;
        width: 100%;
        padding: 6px 6px;
    }
}