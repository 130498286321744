.inner-banner-form {
  background: #e80000;
  padding: 30px 40px;
  border-radius: 10px;
  width: 70%;
  margin: 0 auto;
  border: 1px solid transparent;
  box-shadow: 0 0 10px rgb(0 0 0 / 18%);
}

.inner-banner-form h3 {
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 10px;
}

.inner-banner-form p {
  color: #fff;
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 20px;
}

.inner-banner-form input {
  width: 100%;
  height: 50px;
  margin-bottom: 15px;
  padding: 0 20px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.inner-banner-form textarea {
  width: 100%;
  height: 90px;
  padding: 15px 20px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  resize: none;
}

.inner-banner-form .default_btn.red_btn {
  border-color: #fff;
  width: 100%;
}

@media (max-width: 1366px) {
  .inner-banner-form h3 {
    font-size: 30px;
  }
}

@media (max-width : 1199px) {
  .inner-banner-form {
    padding: 20px 20px;
    width: 80%;
  }
}

@media (max-width : 575px) {
  .inner-banner-form {
    padding: 18px 15px;
    width: 100%;
  }

  .inner-banner-form h3 {
    font-size: 24px;
  }

  .inner-banner-form p {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 15px;
  }
}